<template>
	<div style="height: 100%;">
		<main-table>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input v-model="params.keywords" placeholder="物料名称/物料编码/货架编码" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.warehouses" placeholder="请选择仓库" style="width: 100%;" filterable multiple collapse-tags>
						<el-option v-for="warehouse in warehouses" :key="warehouse.id" :label="warehouse.name" :value="warehouse.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getSuppliers(params)">查询</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" class="scroll-wrapper absolute" height="100%" :data="suppliers" :size="theme.size" @sort-change="sortChange">
				<el-table-column label="物料编码" min-width="100" prop="material_no"></el-table-column>
				<el-table-column label="物料名称" min-width="100" prop="material_name"></el-table-column>
				<el-table-column label="所属仓库" min-width="100" prop="warehouse_name"></el-table-column>
				<el-table-column label="货架编码" min-width="100" prop="rack">
					<template slot-scope="scope">
						<el-input
							ref="followInput"
							size="mini"
							placeholder="请输入货架编码"
							v-model="material.rack"
							v-if="material.id === scope.row.id"
							@keyup.enter.native="saveFollow"
							@blur="saveFollow"
							clearable>
						</el-input>
						<template v-else>
							<p @click="inputFollow(scope.row)" v-if="scope.row.rack">{{scope.row.rack}}</p>
							<p @click="inputFollow({rack: '', id: scope.row.id})" v-else>暂无～</p>
						</template>
					</template>
				</el-table-column>
				<el-table-column label="库存量" min-width="100" prop="amount" sortable="custom"></el-table-column>
				<template v-if="theme.width >= 1420">
					<el-table-column label="更新时间" min-width="150" prop="updated_at"></el-table-column>
					<el-table-column label="创建时间" min-width="150" prop="created_at"></el-table-column>
				</template>
				<el-table-column label="操作" width="80">
					<template slot-scope="scope">
						<el-button type="text" @click="onDelete(scope.row)" :disabled="!$utils.delete($api.URI_WAREHOUSES_MATERIALS)" v-if="!scope.row.deleted_at">删除</el-button>
						<el-button type="text" @click="onRestore(scope.row)" :disabled="!$utils.restore($api.URI_WAREHOUSES_MATERIALS)" v-else>恢复</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getSuppliers({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getSuppliers({ ...params, page: v }) }"
				background>
			</el-pagination>
		</main-table>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import mainTable from '@/components/main-table';

	export default {
		components: {
			mainTable,
		},
		computed: {
			...mapState(['theme', 'admin_user'])
		},
		methods: {
			sortChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				let p = this.params;
					p.order = o;
				this.getSuppliers(this.params);
			},
			onDelete (r) {
				this.$confirm('确定要删除【'+r.material_name+'】供应吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_WAREHOUSES_MATERIALS, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getSuppliers(this.params);
					this.$message.success(msg);
				})
			},
			onRestore (r) {
				this.$confirm('确定要恢复【'+r.material_name+'】供应吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_WAREHOUSES_MATERIALS, {params: {action: 'restore', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getSuppliers(this.params);
					this.$message.success(msg);
				})
			},
			inputFollow (r) {
				this.material = { ...r };
				this.$nextTick(() => {
					this.$refs.followInput.focus();
				});
			},
			saveFollow () {
				this.submitSupplier(this.material, () => {
					this.getSuppliers(this.params);
					// this.material = {
					// 	id: 0,
					// 	rack: ''
					// };
				});
			},
			async submitSupplier (data, onClose) {
				const res = await this.$http.post(this.$api.URI_WAREHOUSES_MATERIALS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose
				})
			},
			async getSuppliers (p, l = false) {
				const res = await this.$http.get(this.$api.URI_WAREHOUSES_MATERIALS, {params: p, headers: {loading: l}});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.suppliers = result.data;
				this.warehouses = result.warehouses;
				this.params = {
					...p,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				warehouses: [],
				suppliers: [],
				supplier: {},
				material: {},
				params: {
					perPage: 10
				}
			}
		},
		async created () {
			this.getSuppliers(this.params, true)
		}
	};
</script>